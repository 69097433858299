import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Safra = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>SAFRA - Sustainable Aerial Forestry Resilience Analytics</h2>
          <figure>
            <img
             src={"/chameleon.png"}
            />
          </figure>
          <h3>CHAMELEON</h3>
          <p>
          <a className="a-blue" href="https://chameleon-heu.eu/" target="_blank">CHAMELEON</a> aims to optimise production and identify potential problems in agriculture, livestock, forestry and rural areas. To achieve this, the CHAMELEON Project use a novel reconfigurable drone, able to modify its configuration and sizing upon demand, which can be deployed in homogeneous or heterogeneous groups to support complex scenarios, as well as a set of existing heterogeneous, modular, interoperable, networked unmanned vehicles systems.
          </p>
          <p>
          CHAMELEON introduces a new business model and ecosystem-oriented AI development platform, specifically designed to revolutionize the agriculture, forestry, and livestock industries. This platform facilitates innovative communication and transactions among stakeholders within these sectors, meeting the complex needs of agricultural, forest, and livestock ecosystems as well as rural environments. It features a versatile UAV hardware and software system, available on-demand and capable of multiple functions.
          </p>
          <p>          
          Furthermore, this project advances a comprehensive framework for large-scale agriculture and forestry surveying, mapping, and assessment. This framework employs a variety of platforms, including UAVs and RPAS, to support a hierarchy of observational scales. Complementing this, CHAMELEON offers a suite of novel UAV-enabled AI applications and tools aimed at promoting sustainable practices across agriculture, forestry, livestock ecosystems, and rural area services.
          </p>
          <hr />
          <h3>Sustainable Aerial Forestry Resilience Analytics - SAFRA</h3>
          <p className="mt-2">
          SAFRA is focused on developing a comprehensive software bundle that simplifies
          the collection of analytical data from the analysis of RGB images collected by drones in forest
          environments. SAFRA’s primary objective is to create a user-friendly platform that facilitates
          collecting and analysing analytical insights from these images, with a particular emphasis on
          assessing forest health. The SAFRA bundle will combine sensorial fusion from Unmanned
          Aerial Vehicles (UAVs) and Remotely Piloted Aircraft Systems (RPAs), open data, AI, and Digital
          Twin technology to deliver a comprehensive yet intuitive analysis of the health status of
          forests, combining the expertise of the proponent company, FORCERA, with state-of-the-art
          open-source tools and frameworks. The software bundle's key features include the integration
          of geolocation and meteorological data, along with the utilisation of this information to adjust
          RGB image contrast and saturation collected by UAV/RPA devices. By combining imaging with
          precise location data and meteorological conditions, the system aims to enhance the
          precision of the detection of indicators of poor leaf health in trees and vegetation and assess
          the impact of factors such as drought and pests on the ecosystem.
          </p>
          <figure>
            <img
             src={"/safra-bundle.png"}
            />
            <figcaption>SAFRA Bundle Technological Approach</figcaption>
          </figure>
          <p style={{paddingTop: 40}}>
          The software will offer image analysis capabilities, relying on integrating geospatial data for
          precise mapping, using meteorological data for added image enhancement, and incorporating
          algorithms for drought, pest detection and early assessment of their impact. SAFRA will be
          hardware agnostic, meaning that the developed approach will have universal support for
          UAVs/RPAs. Additionally, the system will provide tools for generating reports and
          visualisations, enabling forest managers, researchers, and conservationists to interpret and
          communicate their findings effectively. By being part of the CHAMELEON ecosystem, FORCERA
          will attempt to promote the integration of other CHAMELEON bundles related to forestry in
          the created SAFRA analytics dashboard, allowing prospective users to have a one-stop shop
          Digital Twin for collecting insights on data-enabled forestry health status.
          </p>
          <p>
          SAFRA aims to benefit various users, including forest managers, environmental researchers,
          and public entities, by improving forest health assessment through the combination of AI and
          Digital Twin technologies and the processing of UAV/RPA data, geolocation, and
          meteorological information to support informed decision making in forest conservation and
          sustainable management efforts based on forest health metrics and insights.
          </p>
          <h3>Expected Impact</h3>
          <p>The SAFRA sub-project under the CHAMELEON initiative is poised to revolutionize the management and sustainability of European forests. By integrating advanced technologies such as drones and AI, SAFRA offers a robust solution for continuous, comprehensive monitoring and analysis of forest health. This allows for early detection of issues and proactive forest management, aligning perfectly with CHAMELEON's goals of enhancing the efficiency of forestry operations and ensuring sustainable environmental practices. The implementation of SAFRA is expected to significantly improve the precision of forest health assessments, thereby contributing to more effective forest management strategies and better conservation outcomes.
          </p>
          <p>
          Moreover, the incorporation of the SAFRA bundle into the CHAMELEON Bundle Store is set to create a synergistic effect with other forestry-related digital tools and services. This integration fosters a collaborative ecosystem that promotes continuous improvement and innovation within the CHAMELEON framework.
          </p>
          <figure className="ue-horizon-logo">
              <img
             src={"/horizon-2020.jpeg"}
              />
              <figcaption>This project has received cascading funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 101060529.
              </figcaption>
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Safra;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
` 