import React, { useState, useEffect } from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from 'gatsby'


import LinkedIn from '../assets/svg/linkedin.svg';
import Youtube from '../assets/svg/youtube.svg';


const Layout = ({children}) => {
  const [toggleNav, setToggleNav] = useState(false)

  const serviceScroll = () => {
    setToggleNav(!toggleNav)
    if(document.querySelector("#services")){
      setTimeout(() => {
        const el = document.querySelector("#services")
        const y = el.getBoundingClientRect().y
        window.scrollTo(0, y)
      }, 550)      
    }
  }

  return (
    <div className={`site-wrapper ${toggleNav ? `site-wrapper--head-open` : ``}`}>
      <header className="head">
        <div className="head__container">
          <a className="head__burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <div className="head__nav-left">
            <Link className="head__logo" to={`/`}>
              <img src={"/logo.png"} alt="Site Logo"></img>
            </Link>
          </div>
          <nav className="head__nav-right">
            <ul className="head__nav">
              <li className="head__nav-link" onClick={() => setToggleNav(!toggleNav)}>
                <Link to={`/`}>Home</Link>
              </li>
              <li className="head__nav-link" onClick={serviceScroll}>
                <AnchorLink to={`/#services`} >Services</AnchorLink>
              </li>
              <li className="head__nav-link" onClick={() => setToggleNav(!toggleNav)}>
                <Link to={`/about`}>About</Link>
              </li>
              <li className="head__nav-link" onClick={() => setToggleNav(!toggleNav)}>
                <Link to={`/contacts`}>Contact Us</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <main>
        {children}
      </main>
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__content">
            <Link className="footer__logo" to={`/`}>
              <img src={"/logo.png"} alt="Site Logo"></img>
            </Link>
            <ul className="footer__list">
              <li className="footer__item footer__item--d-none">
                <div>Backed by:</div>
              </li>
              <li className="footer__item">
                <img src={"/Logo_Portugal_2020.png"} alt="Portugal 2020"></img>
              </li>
              <li className="footer__item">
                <img src={"/Logo_Compete2020.png"} alt="Compete 2020"></img>
              </li>
              <li className="footer__item">
                <img src={"/iapmei-logo.png"} alt="IAPMEI"></img>
              </li>
              <li className="footer__item  footer__item--d-none">
                <div>Follow us:</div>
              </li>
              <li className="footer__item">
                <a href="https://www.youtube.com/channel/UC7c32BX4XlhNWqZdf4TQjhg" target="_blank" rel="noreferrer"><Youtube /></a>
              </li>
              <li className="footer__item">
                <a href="http://linkedin.com/company/forcera-analytics" target="_blank" rel="noreferrer"><LinkedIn /></a>
              </li>
            </ul>            
          </div>
        </div>
      </footer>      
    </div>
  )
}

export default Layout


///<div className="footer__copyright">
///&copy; {new Date().getFullYear()} Forcera. All rights reserved
///(</div>
